import {Component, ViewChild} from '@angular/core';
import {IssueCertificateService} from "../../../../../services/issueCertificateService.service";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import Swal from "sweetalert2";
import {IIssueCertificateResponse} from "../../../../../interfaces/IIssueCertificate";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'app-step-1',
  standalone: true,
  imports: [],
  templateUrl: './step-1.component.html',
  styleUrl: './step-1.component.scss'
})
export class Step1Component {
  permissionDownloaded: boolean = false;
  importBrowserInstruction: boolean = false;
  isAddNewActive: boolean = false;
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  activeSection: string = ''; // This will be set based on the detected browser.

  dataSource = new MatTableDataSource<any>();
  isEditable: boolean = true;
  constructor(
    private dataService: IssueCertificateService,
    private router: Router,
    public dialog: MatDialog,
  ) {
  }

  navigateToIssueCertificates() {
    this.router.navigate(['/landing-page/certificates/issue']);
  }
  issueNewCertificate() {
    Swal.fire({
      title: 'Create a new Certificate? ',
      text: 'By clicking yes, you will be issued a new certificate. Please make sure you have your mobile phone close. A verification code will be sent to you via SMS.',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      reverseButtons: true,
      showClass: {
        popup: `animate__animated animate__fadeInUp animate__faster`
      },
      hideClass: {
        popup: `animate__animated animate__fadeOutDown animate__faster`
      },
      didRender: () => {
        const actions = document.querySelector('.swal2-actions');
        const confirmButton = document.querySelector('.swal2-confirm');
        const cancelButton = document.querySelector('.swal2-cancel');
        if (actions && confirmButton && cancelButton) {
          confirmButton.setAttribute('style', 'margin-right: 20px !important; background-color: #337AB7 !important');
          cancelButton.setAttribute('style', 'margin-left: 20px !important; background-color: #f2f2f2 !important; color: #000 !important');
          actions.setAttribute('style', 'width: 100% !important;justify-content: space-between !important');
        }
      }
    }).then((result) => {
      console.log(result);
      if (result.isConfirmed) {
        this.router.navigate(['/landing-page/certificates/step2']);

        this.dataService.issueCertificate().subscribe(
          (res) => {
            const response = res as IIssueCertificateResponse;
            console.log(res);

            const { data, fileName, fileType } = response;

            // Convert Base64 to Blob
            const byteCharacters = atob(data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: fileType });

            // Create a download link and click it programmatically
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.href = url;
            link.download = fileName;
            link.click();
            this.showSMSVerificationDialog(response.phoneDigits);
            // Clean up by revoking the Object URL
            URL.revokeObjectURL(url);
            this.getData();
          },
          (err) => {
            console.error('Error downloading the file', err);
            Swal.fire({
              title: 'Cannot create Certificate',
              text: 'Please contact NeHA support line for assistance. Sorry for the inconvenience.',
              reverseButtons: true,
              showClass: {
                popup: `animate__animated animate__fadeInUp animate__faster`
              },
              hideClass: {
                popup: `animate__animated animate__fadeOutDown animate__faster`
              },
              didRender: () => {
                const actions = document.querySelector('.swal2-actions');
                const confirmButton = document.querySelector('.swal2-confirm');
                const cancelButton = document.querySelector('.swal2-cancel');
                if (actions && confirmButton && cancelButton) {
                  confirmButton.setAttribute('style', 'margin-right: 20px !important; background-color: #337AB7 !important');
                  cancelButton.setAttribute('style', 'display: none !important; ');
                  actions.setAttribute('style', 'width: 100% !important;justify-content: flex-end !important');
                }
              }
            });
            console.log(err);
          }
        );
      } else {
        this.isEditable = true;
        this.isAddNewActive = false;
      }
    });
    this.permissionDownloaded = true;
  }
  showSMSVerificationDialog(phoneDigits: string) {
    Swal.fire({
      title: `An SMS was sent to the phone *****${phoneDigits}.`,
      text: 'Please check your phone for a new SMS. Enter the code into the Certificate import procedure to verify your identity.',
      showClass: {
        popup: `animate__animated animate__fadeInUp animate__faster`
      },
      hideClass: {
        popup: `animate__animated animate__fadeOutDown animate__faster`
      },
      didRender: () => {
        const actions = document.querySelector('.swal2-actions');
        const confirmButton = document.querySelector('.swal2-confirm');
        if (actions && confirmButton) {
          confirmButton.setAttribute('style', 'margin-right: 20px !important; background-color: #337AB7 !important');
        }
      }
    });
  }

  private downloadPfxFile(res: any) {
    const blob = new Blob([res], { type: 'application/x-pkcs12' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'certificate.pfx';
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
  getData() {
    this.dataService.list().subscribe((data) => {
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;

      this.sort.active = 'expiryDate';
      this.sort.direction = 'desc';
      this.dataSource.sort = this.sort;
      console.log(data);
    });
  }

}
