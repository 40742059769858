import { Component } from '@angular/core';
import {NgForOf} from "@angular/common";

@Component({
  selector: 'app-assigned-pharmacies-table',
  standalone: true,
  imports: [
    NgForOf
  ],
  templateUrl: './assigned-pharmacies-table.component.html',
  styleUrl: './assigned-pharmacies-table.component.scss'
})
export class AssignedPharmaciesTableComponent {
  pharmacies = [
    { name: 'Pharmacy 1', address: 'test address', district: 'Nicosia', phoneNumber: '99887766' },
    { name: 'Pharmacy 2', address: 'test address', district: 'Nicosia', phoneNumber: '99887766' },
    { name: 'Pharmacy 3', address: 'test address', district: 'Nicosia', phoneNumber: '99887766' }
  ];

  removePharmacy(index: number) {
    this.pharmacies.splice(index, 1);
  }
}
