import {Component, OnInit} from '@angular/core';
import {NgIf} from "@angular/common";
import {IssueCertificateService} from "../../../../../services/issueCertificateService.service";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-step-3',
  standalone: true,
    imports: [
        NgIf
    ],
  templateUrl: './step-3.component.html',
  styleUrl: './step-3.component.scss'
})
export class Step3Component implements OnInit {

  constructor(
    private dataService: IssueCertificateService,
    private router: Router,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.activeSection = this.detectBrowser();

  }
  toggleSection(section: string) {
    if (this.activeSection === section) {
      this.activeSection = '';  // Close the active section
    } else {
      this.activeSection = section;  // Open the clicked section
    }
  }
  detectBrowser(): string {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.includes('edg')) { // Check for Edge first
      return 'edge';
    } else if (userAgent.includes('chrome')) { // Then check for Chrome
      return 'chrome';
    } else if (userAgent.includes('safari') && !userAgent.includes('chrome')) { // Safari check (excluding Chrome)
      return 'safari';
    } else {
      return ''; // Default case if the browser is not recognized
    }
  }

  activeSection: string = ''; // This will be set based on the detected browser.

  testPermissionClicked() {
    this.router.navigate(['/landing-page/certificates/issue']);
  }

}
