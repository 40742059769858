import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { PharmacyService } from '../../../../../services/pharmacy.service';
import { IDistrict } from '../../../../../interfaces/IDistrict';

interface Pharmacy {
  name: string;
  address: string;
  district: string;
  phone: string;
}

@Component({
  selector: 'app-assign-pharmacists-to-pharmacy',
  standalone: true,
  imports: [FormsModule, NgForOf, NgIf, NgClass],
  templateUrl: './assign-pharmacists-to-pharmacy.component.html',
  styleUrl: './assign-pharmacists-to-pharmacy.component.scss',
})
export class AssignPharmacistsToPharmacyComponent implements OnInit {
  pharmacistName = '';
  selectedDistrict: string = '';
  isPopUpOpen: boolean = false;
  selectedPharmacy: string | null = null;
  selectedLanguage = 'en'; // en or el
  districts: IDistrict[] = [];
  isDistrictInvalid: boolean = false;

  // Test pharmacy data
  pharmacies: Pharmacy[] = [
    {
      name: 'Test Pharmacy 1',
      address: '123 Test St, Nicosia',
      district: 'Nicosia',
      phone: '99887766',
    },
    {
      name: 'Test Pharmacy 2',
      address: '456 Test Ave, Larnaca',
      district: 'Larnaca',
      phone: '99887767',
    },
    {
      name: 'Test Pharmacy 3',
      address: '789 Test Blvd, Paphos',
      district: 'Paphos',
      phone: '99887768',
    },
    {
      name: 'Test Pharmacy 4',
      address: '101 Test Lane, Limassol',
      district: 'Limassol',
      phone: '99887769',
    },
    {
      name: 'Test Pharmacy 5',
      address: '202 Test Road, Nicosia',
      district: 'Nicosia',
      phone: '99887770',
    },
  ];

  filteredPharmacies: Pharmacy[] = [];

  constructor(
    private router: Router,
    private pharmacyService: PharmacyService,
  ) {}

  loadDistricts() {
    this.pharmacyService.getDistrictsLookupTable().subscribe({
      next: (data) => {
        this.districts = data;
      },
      error: (error) => {
        console.error('Error fetching districts:', error);
      },
    });
  }

  validateAndSearch() {
    if (!this.selectedDistrict) {
      this.isDistrictInvalid = true;
    } else {
      this.isDistrictInvalid = false;
      this.searchPharmacies();
    }
  }

  searchPharmacies() {
    if (this.selectedDistrict === 'All') {
      this.filteredPharmacies = this.pharmacies;
    } else {
      this.filteredPharmacies = this.pharmacies.filter(
        (pharmacy) => pharmacy.district === this.selectedDistrict,
      );
    }
  }

  openModal(pharmacyName: string) {
    this.selectedPharmacy = pharmacyName;
    this.isPopUpOpen = true;
  }

  closeModal() {
    this.isPopUpOpen = false;
    this.selectedPharmacy = null;
  }

  finish() {
    this.isPopUpOpen = false;
    console.log('Finished selection for:', this.selectedPharmacy);
    this.selectedPharmacy = null;
    this.router.navigate(['/landing-page/certificates/assignments']);
  }

  ngOnInit(): void {
    console.log('AssignPharmacistsToPharmacyComponent');
    this.loadDistricts();
  }
}
