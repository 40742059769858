import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { IssueCertificateService } from 'src/app/services/issueCertificateService.service';
import { LandingPageService } from 'src/app/services/landing-page.service';
import { Router } from '@angular/router';
import { IIssueCertificateResponse } from 'src/app/interfaces/IIssueCertificate';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'issue-certificates-table',
  templateUrl: './issue-certificates-table.component.html',
  styleUrls: ['./issue-certificates-table.component.scss'],
})
export class IssueCertificatesTableComponent implements OnInit {
  title: string = 'Certificates';
  formGroup!: FormGroup;
  isAddNewActive: boolean = false;
  displayedColumns: string[] = ['name', 'issuer', 'expiryDate', 'status'];
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource = new MatTableDataSource<any>();
  isEditable: boolean = true;
  isNewCertificateActionAvailable: boolean;
  isOperator = false;
  constructor(
    private dataService: IssueCertificateService,
    private authService: LandingPageService,
    private router: Router,
    public dialog: MatDialog,
    private toastr: ToastrService
  ) {
    this.router.navigate(['/landing-page/certificates/issue']);
  }
  ngOnInit(): void {
    this.authService.currentUserRole.subscribe((role) => {
      console.log(role);
      this.isNewCertificateActionAvailable =
        role.toLocaleLowerCase() !== 'operator';
      if (role.toLocaleLowerCase() === 'operator') {
        this.isOperator = true;
        this.displayedColumns = [
          'name',
          'issuer',
          'expiryDate',
          'status',
          'action',
        ];
      }
    });

    this.getData();
  }
  getData() {
    this.dataService.list().subscribe((data) => {
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;

      this.sort.active = 'expiryDate';
      this.sort.direction = 'desc';
      this.dataSource.sort = this.sort;
      console.log(data);
    });
  }
  issueNewCertificate() {
    this.isAddNewActive = true;
    Swal.fire({
      title: 'Create a new Certificate? ',
      text: 'By clicking yes, you will be issued a new certificate. Please make sure you have your mobile phone close. A verification code will be sent to you via SMS.',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      reverseButtons: true ,
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `
      },
      didRender: () => {
        const actions = document.querySelector('.swal2-actions');
        const confirmButton = document.querySelector('.swal2-confirm');
        const cancelButton = document.querySelector('.swal2-cancel');
        if (actions && confirmButton && cancelButton) {
          confirmButton.setAttribute('style', 'margin-right: 20px !important; background-color: #337AB7 !important');
          cancelButton.setAttribute('style', 'margin-left: 20px !important; background-color: #f2f2f2 !important; color: #000 !important');
          actions.setAttribute('style', 'width: 100% !important;justify-content: space-between !important');

        }}
    }).then((result) => {
      console.log(result);
      if (result.isConfirmed) {
        this.router.navigate(['/landing-page/certificates/step2']);
        this.isAddNewActive = false;

        this.dataService.issueCertificate().subscribe(
          (res) => {
            const response = res as IIssueCertificateResponse;
            console.log(res);

            const { data, fileName, fileType } = response;

            // Convert Base64 to Blob
            const byteCharacters = atob(data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: fileType });

            // Create a download link and click it programmatically
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.href = url;
            link.download = fileName;
            link.click();
            this.showSMSVerificationDialog(response.phoneDigits);
            // Clean up by revoking the Object URL
            URL.revokeObjectURL(url);
            this.getData();
          },
          (err) => {
            console.error('Error downloading the file', err);
            Swal.fire({
              title: 'Cannot create Certificate',
              text: 'Please contact NeHA support line for assistance. Sorry for the inconvenience.',
              reverseButtons: true,
              showClass: {
                popup: `
                  animate__animated
                  animate__fadeInUp
                  animate__faster
                `
              },
              hideClass: {
                popup: `
                  animate__animated
                  animate__fadeOutDown
                  animate__faster
                `
              },
              didRender: () => {
                const actions = document.querySelector('.swal2-actions');
                const confirmButton = document.querySelector('.swal2-confirm');
                const cancelButton = document.querySelector('.swal2-cancel');
                if (actions && confirmButton && cancelButton) {
                  confirmButton.setAttribute('style', 'margin-right: 20px !important; background-color: #337AB7 !important');
                  cancelButton.setAttribute('style', 'display: none !important; ');
                  actions.setAttribute('style', 'width: 100% !important;justify-content: flex-end !important');

                }}
            })
            console.log(err);
          }
        );


      } else {
        this.isEditable = true;
        this.isAddNewActive = false;
      }
    });
  }
  showSMSVerificationDialog(phoneDigits: string) {
    Swal.fire({
      title:  `An SMS was send to the phone *****${phoneDigits}.`,
      text: 'Please check your phone for a new SMS. Enter the code into the Certificate import procedure to verify your identity.',

      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `
      },
      didRender: () => {
        const actions = document.querySelector('.swal2-actions');
        const confirmButton = document.querySelector('.swal2-confirm');
        const cancelButton = document.querySelector('.swal2-cancel');
        if (actions && confirmButton && cancelButton) {
          confirmButton.setAttribute('style', 'margin-right: 20px !important; background-color: #337AB7 !important');

        }}});
  }

  deleteItem(id: string) {
    Swal.fire({
      title: 'Revoke Certificate? ',
      text: 'By clicking yes, you will revoke the certificate, and this action cannot be undone. Are you sure you want to continue?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      reverseButtons: true ,
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `
      },
      didRender: () => {
        const actions = document.querySelector('.swal2-actions');
        const confirmButton = document.querySelector('.swal2-confirm');
        const cancelButton = document.querySelector('.swal2-cancel');
        if (actions && confirmButton && cancelButton) {
          confirmButton.setAttribute('style', 'margin-right: 20px !important; background-color: #48CDA1 !important');
          cancelButton.setAttribute('style', 'margin-left: 20px !important; background-color: #f2f2f2 !important; color: #000 !important');
          actions.setAttribute('style', 'width: 100% !important;justify-content: space-between !important');

        }}
    }).then((result) => {
      console.log(result);
      if (result.isConfirmed) {
    this.dataService.revoke(id).subscribe({
      next: (res: any) => {
        this.toastr.success('Certificate Revoked');
        this.getData();
      },
      error: (err: any) => {
        Swal.fire({
          icon: 'error',
          text:
            err.error.errorMessage || err.error.error || 'Something went wrong',
        });
        console.log(err);
        this.getData;
      },
    });}
  })}

  private downloadPfxFile(res: any) {
    const blob = new Blob([res], { type: 'application/x-pkcs12' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'certificate.pfx';
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
}
