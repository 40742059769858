import {Component,Input, OnInit} from '@angular/core';
import { IUserProfile, IPharmacistDetails } from 'src/app/interfaces/IUserProfile';
import {Router} from "@angular/router";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-welcome-step-for-pharmacists',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './welcome-step-for-pharmacists.component.html',
  styleUrl: './welcome-step-for-pharmacists.component.scss'
})
export class WelcomeStepForPharmacistsComponent implements OnInit {
  @Input() userProfile: IUserProfile;   // This will hold the user profile data
  isPharmacyOwner: boolean = false;     // Default flag for pharmacy owner

  constructor(    private router: Router) {
    }

  ngOnInit(): void {
    // Check if the user is a pharmacist and extract the isPharmacyOwner flag
    if (this.userProfile && this.userProfile.role === 'pharmacist') {
      const pharmacistDetails = this.userProfile.details as IPharmacistDetails;
      this.isPharmacyOwner = pharmacistDetails.isPharmacyOwner;
    }
  }
  goToPermissions() {
    this.router.navigate(['/landing-page/certificates/step1']);
  }

  goToAccess() {
    this.router.navigate(['/landing-page/certificates/assignPharmacistsToPharmacy']);

  }

}
