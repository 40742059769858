import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RouterOutlet} from "@angular/router";
import {LandingPageService} from "../../../services/landing-page.service";
import {Subscription, take} from "rxjs";

@Component({
  selector: 'app-healthcare-professionals-page',
  standalone: true,
  imports: [
    RouterOutlet
  ],
  templateUrl: './healthcare-professionals-page.component.html',
  styleUrl: './healthcare-professionals-page.component.scss'
})
export class HealthcareProfessionalsPageComponent implements OnInit, OnDestroy {
  userRole = '';
  private navigationSubscription: Subscription;

  constructor(private route: ActivatedRoute, private router: Router) {

  }

  ngOnInit(): void {
   this.navigationSubscription =  this.route.queryParams.pipe(take(1)).subscribe(params => {
      const receivedString = params['param']; // This retrieves the passed string
      console.log('Received string:', receivedString);
      console.log(receivedString);

     if (receivedString !== 'pharmacist') {
        console.log('nagivating to step1')
        this.router.navigate(['/landing-page/certificates/step1']);
      }
    })
  }
  ngOnDestroy(): void {
    console.log('Destroying component');
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
  userHasRole(requiredRole: string | string[]): boolean {
    if (requiredRole instanceof Array) {
      return (
        requiredRole.filter(
          (r) => r.toLocaleLowerCase() === this.userRole.toLocaleLowerCase()
        ).length > 0
      );
    } else {
      return (
        requiredRole.toLocaleLowerCase() === this.userRole.toLocaleLowerCase()
      );
    }
  }
}
