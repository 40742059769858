import { Component } from '@angular/core';
import {IssueCertificateService} from "../../../../../services/issueCertificateService.service";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-step-2',
  standalone: true,
  imports: [],
  templateUrl: './step-2.component.html',
  styleUrl: './step-2.component.scss'
})
export class Step2Component {


  constructor(
    private dataService: IssueCertificateService,
    private router: Router,
    public dialog: MatDialog,
  ) {
  }

  nextBtnClicked() {
    this.router.navigate(['/landing-page/certificates/step3']);

  }
}
